import { useEffect, useState } from 'react';
import Transcript from './Transcript';
import Comments from './Comments';
import Summary from './Summary';

export default function Tabs({ comments, summary }: { comments: any[], summary: string }) {
  const hasSummary = summary && summary.length;
  const [activeTab, setActiveTab] = useState(hasSummary ? 'summary' : 'transcript');

  useEffect(() => {
    if(hasSummary) {
      setActiveTab('summary');
    }
  }, [hasSummary]);

  return (
    <div className="w-full mx-auto mt-3">
      <div className="border-b border-gray-200">
        <nav className="flex -mb-px" aria-label="Tabs">
          {hasSummary && (
            <button
              onClick={() => setActiveTab('summary')}
              className={`${
                activeTab === 'summary'
                  ? 'border-gradient-third text-gradient-third'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm`}
              aria-selected={activeTab === 'summary'}
              role="tab"
            >
              Summary
              <span className="bg-gray-600 text-white ml-2 px-2 py-0.5 rounded-full text-xs font-medium">
                Generated
              </span>
            </button>
          )}
          <button
            onClick={() => setActiveTab('transcript')}
            className={`${
              activeTab === 'transcript'
                ? 'border-gradient-third text-gradient-third'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm`}
            aria-selected={activeTab === 'transcript'}
            role="tab"
          >
            Transcript
          </button>
          <button
            onClick={() => setActiveTab('comments')}
            className={`${
              activeTab === 'comments'
                ? 'border-gradient-third text-gradient-third'
                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
            } whitespace-nowrap py-4 px-6 border-b-2 font-medium text-sm`}
            aria-selected={activeTab === 'comments'}
            role="tab"
          >
            Comments
            <span className="bg-gray-100 text-gray-600 ml-2 px-2 py-0.5 rounded-full text-xs font-medium">
              {comments.length || 0}
            </span>
          </button>
        </nav>
      </div>

      <div className="mt-4">
        {hasSummary && (
          <div
            className={`${activeTab === 'summary' ? 'block' : 'hidden'}`}
            role="tabpanel"
            aria-hidden={activeTab !== 'summary'}
          >
            <Summary html={summary} />
          </div>
        )}
        <div
          className={`${activeTab === 'transcript' ? 'block' : 'hidden'}`}
          role="tabpanel"
          aria-hidden={activeTab !== 'transcript'}
        >
          <Transcript />
        </div>
        <div
          className={`${activeTab === 'comments' ? 'block' : 'hidden'}`}
          role="tabpanel"
          aria-hidden={activeTab !== 'comments'}
        >
          <Comments comments={comments} />
        </div>
      </div>
    </div>
  );
}