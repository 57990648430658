interface SummaryProps {
  html: string;
}

export default function Summary({ html }: SummaryProps) {
  return (
    <div className="max-w-3xl mx-auto pb-10 mb-10">
      <div className="prose prose-lg">
        <div
          dangerouslySetInnerHTML={{ __html: html }}
          className="px-4 py-2 font-spectral text-[#242424] text-xl summary"
        />
      </div>
    </div>
  );
}