import { useEffect, useState } from "react";
import useQueryParams from "../../hooks/use-query-params";
import AppleButton from "../buttons/AppleButton";
import GoogleButton from "../buttons/GoogleButton";

export default function LiveInvite() {
  // @ts-ignore
  const [params] = useQueryParams();
  const { roomCode, accessKey } = params;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  return (
    <div className="min-h-screen w-full flex flex-col items-center justify-center px-4 bg-[#005599]">
      <div className="max-w-md w-full bg-[#001533] rounded-lg shadow-xl p-8">
        <div className="text-center">
          <div className="flex flex-col items-center justify-center">
            <p className="text-gray-400 text-lg">
              You have been invited to a live room.
            </p>
          </div>

          {isMobile ? (
            <a 
              href={`jacketfm://live?roomCode=${roomCode}${accessKey ? `&accessKey=${accessKey}` : ''}`}
              className="inline-block w-full bg-[#003366] hover:bg-[#005599] 
                         text-white font-semibold py-3 px-6 rounded-lg
                         transition duration-300 ease-in-out transform hover:scale-105
                         shadow-lg hover:shadow-xl mt-2"
            >
              <div className="flex items-center justify-center space-x-2">
                <svg 
                  className="w-6 h-6" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                  />
                </svg>
                <span>Open in Jacket.fm</span>
              </div>
            </a>
          ) : (
            <div className="text-center space-y-4">
              <div className="text-white text-xl font-medium">
                This link has to be opened in the Jacket.fm app
              </div>
              <div className="text-gray-400">
                Please open this page on your mobile device
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Add this new div for app download links */}
      <div className="flex w-full max-w-md flex-row px-2 mt-4 md:px-0 items-center justify-between">
        <a href="/" title='Jacket.fm home'>
            <img alt="Jacket.fm logo" src="/favicon.png" className="w-[38px] h-[38px]" />
        </a>
        <div className='flex flex-row md:gap-x-4'>
          <AppleButton collapsible />
          <GoogleButton collapsible />
        </div>
      </div>
    </div>
  );
}